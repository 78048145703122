import { Button, Layout, SEO, SuccessIcon } from '@renderbus/common/components'
import React from 'react'
import InputField from '../molecules/nvidia-form/input-field'
import { NVIDIAList, NVIDIACollectMessage } from '../constant/nvidia'
import {
  FormButton,
  FormContainer,
  FormFieldContainer,
  FormFieldInputBox,
  FormFieldTitle,
  FormInfo,
  FormLayout,
  FormTitle,
  FormTips,
  FormFieldMessageTitle,
  FormFieldCheckBox,
  NavigationLink,
  FormInputFileld,
} from './nvidia-form.atom'
import { API, Axios } from '@renderbus/common/utils'
import { isTest } from '@renderbus/common/service'

class NvidiaForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      form: {
        name: '',
        company: '',
        email: '',
        phone: '',
        industry: '',
        post: '',
        purchasePlan: '',
        isBudget: '',
        channel: '',
        isNeedHelp: '',
        otherChannelValue: '',
        agreeToPrivacy: 'check',
        consentData: 'check',
        nvidiaEnterprise: 'check',
        nvidiaDeveloper: 'check',
      },
      activityId: 0,
      prizeList: [],
      submitText: '',
      isSuccess: false,
    }
    this.handleSumbit = this.handleSumbit.bind(this)
    this.handleUpdateValue = this.handleUpdateValue.bind(this)
  }
  instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)
  otherText = '其他'
  get disabledForm() {
    let checkData = this.state.form
    if (this.state.form.channel !== this.otherText) {
      const { otherChannelValue, ...params } = this.state.form
      checkData = params
    }
    return !Object.values(checkData).every(Boolean)
  }

  handleUpdateValue(value, name) {
    let data = value
    if (['agreeToPrivacy', 'consentData', 'nvidiaEnterprise', 'nvidiaDeveloper'].includes(name)) {
      data = value ? '' : 'check'
    }
    this.setState(preState => ({
      form: { ...preState.form, [name]: data },
    }))
  }
  handleSumbit() {
    const { activityId, form } = this.state
    const formString = `
      <table>
      ${NVIDIAList.map(
        data => `
        <tr>
          <td style='border: 1px solid #333'>${data.title}</td>
          <td style='border: 1px solid #333'>${form[data.name]}</td>
        </tr>
        `,
      ).join()}
      </table>
    `
    let formData = { ...form }
    Reflect.deleteProperty(formData, 'nvidiaEnterprise')
    Reflect.deleteProperty(formData, 'nvidiaDeveloper')
    if (this.state.form.channel === this.otherText) {
      formData.channel = formData.otherChannelValue
    }
    const { agreeToPrivacy, consentData, otherChannelValue, ...activityFormData } = formData
    const params = Object.assign({}, { activityId, form: formString }, activityFormData)
    this.instance.post(API.event.uploadActivityForm, params).then(res => {
      if (res && res.code === 200) {
        this.setState({
          submitText: '恭喜您获得抽奖券！点击充值页面右下角活动图标查看抽奖券！',
          isSuccess: true,
        })
      }
    })
  }
  async checkQualification() {
    let submitText = ''
    const hasActivityQualification = await this.instance.post(API.event.getSupportActivity)
    if (!hasActivityQualification || !hasActivityQualification.data)
      window.location.href = `${isTest() ? API.auth.testHost : API.auth.host}/center/user/topUp`
    this.setState({ activityId: hasActivityQualification.data[0].id })
    const { code, data } = await this.instance.post(API.event.getUserPrizeCode, {
      activityId: hasActivityQualification.data[0].id,
    })
    if (data && code === 200) {
      if (!data.length) submitText = '请充值后再填写问卷!'
      const isExsitQuestionnaire = data && data.filter(item => !item.payRecordId).length
      if (isExsitQuestionnaire) {
        submitText = '恭喜您获得抽奖券！点击充值页面右下角活动图标查看抽奖券！'
        this.setState({
          isSuccess: true,
        })
      } else {
        this.setState({
          prizeList: data,
        })
      }
    } else if (hasActivityQualification && [602, 603].includes(hasActivityQualification.code)) {
      submitText = '请点击右上角进行登录！'
    } else {
      submitText = '活动已下线'
    }
    this.setState({ submitText })
  }

  componentDidMount() {
    this.checkQualification()
  }

  render() {
    const { location } = this.props
    const { submitText, isSuccess, prizeList } = this.state
    return (
      <Layout location={location}>
        <SEO title='瑞云问卷调查' description='瑞云问卷调查' keywords='瑞云问卷调查' />
        <FormLayout>
          <FormContainer>
            <React.Fragment>
              <FormTitle>填问卷，多得一张抽奖券!</FormTitle>
              <FormInfo>
                填写此问卷，即可额外再获得抽奖券一张！
                <br />
                已有抽奖券：
                {prizeList.map((prize, index) => (
                  <p key={index}>抽奖券码 {prize.ticketCode}</p>
                ))}
              </FormInfo>
            </React.Fragment>
            {NVIDIAList.map((data, index) => (
              <FormFieldContainer key={index}>
                <FormFieldTitle>
                  {index + 1}: {data.title}
                </FormFieldTitle>
                <FormFieldInputBox>
                  <InputField
                    info={data}
                    value={this.state.form[data.name]}
                    onChange={this.handleUpdateValue}
                  />
                  {data.name === 'channel' && this.state.form.channel === this.otherText && (
                    <FormInputFileld
                      value={this.state.form.otherChannelValue}
                      onChange={e => this.handleUpdateValue(e.target.value, 'otherChannelValue')}
                      name='channel'
                    />
                  )}
                </FormFieldInputBox>
              </FormFieldContainer>
            ))}
            {NVIDIACollectMessage.map((data, index) => (
              <FormFieldContainer key={index}>
                <FormFieldCheckBox style={{ position: 'relative' }}>
                  <input
                    name={data.name}
                    type='checkbox'
                    checked={this.state.form[data.name]}
                    value={this.state.form[data.name]}
                    onChange={e => data.name && this.handleUpdateValue(e.target.value, data.name)}
                  />
                  <FormFieldMessageTitle>
                    {data.title.map(el => {
                      if (data.linkText === el)
                        return (
                          <NavigationLink href={data.link} target='_blank' rel='nofollow' key={el}>
                            {' '}
                            {el}
                          </NavigationLink>
                        )
                      return el
                    })}
                  </FormFieldMessageTitle>
                </FormFieldCheckBox>
              </FormFieldContainer>
            ))}

            <FormButton>
              <Button onClick={this.handleSumbit} disabled={this.disabledForm} id='task_xgttxwj'>
                提交
              </Button>
            </FormButton>
          </FormContainer>
        </FormLayout>
        {submitText && (
          <FormTips>
            <p>
              {isSuccess && <SuccessIcon />}
              {submitText}
            </p>
          </FormTips>
        )}
      </Layout>
    )
  }
}

export default NvidiaForm
